<template>
  <div>
    <v-row justify="center" class="mt-3 pt-5">
      <v-col cols="auto" class="text-center">
        <v-img width="50px" :src="user.data.verein.logo" />
      </v-col>
      <v-col cols="auto" align-self="center">
        <h2 class="white--text">{{ user.data.verein.name }}</h2>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-5" justify="center">
      <v-col cols="12" class="py-0">
        <cardbutton
          :title="'Vereinsbenutzer verwalten'"
          :icon="'mdi-account-group'"
          :disabled="user.data.rolle != 'Vorstand'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          to="/verein/benutzerverwaltung"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <cardbutton
          :title="'Vereinsbenutzer anlegen'"
          :icon="'mdi-account-plus'"
          :disabled="user.data.rolle != 'Vorstand'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          to="/verein/erstelle-benutzer"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <cardbutton
          :title="'Verein bearbeiten'"
          :icon="'mdi-shield-edit'"
          :disabled="user.data.rolle != 'Vorstand'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          to="/verein/bearbeiten"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <cardbutton
          :title="'Gespeicherte Personen'"
          :icon="'mdi-account-details'"
          :disabled="user.data.rolle != 'Vorstand'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          to="/verein/personen"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <cardbutton
          :title="'Aktivitäten verwalten'"
          :icon="'mdi-card-account-details-star'"
          :disabled="user.data.rolle != 'Vorstand'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          to="/verein/aktivitaeten"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <cardbutton
          :title="'Sportstätten verwalten'"
          :icon="'mdi-stadium-variant'"
          :disabled="user.data.rolle != 'Vorstand'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          to="/verein/sportstaetten"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <cardbutton
          :title="'Hygienekonzept bearbeiten'"
          :icon="'mdi-hand-wash'"
          :disabled="user.data.rolle != 'Vorstand'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          to="/verein/hygienekonzept"
        />
      </v-col>
      <v-col cols="12" class="py-0" v-if="false">
        <cardbutton
          :title="'SCHNITTSTELLE GESUNDHEITSAMT'"
          :icon="'mdi-connection'"
          :disabled="user.data.rolle != 'Vorstand'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          to="/verein/ga-connect"
        />
      </v-col>
      <v-col cols="12" class="py-0" v-if="!template.var.deactivate_abteilung">
        <cardbutton
          :title="'Abteilungen verwalten'"
          :icon="'mdi-account-supervisor-circle'"
          :disabled="user.data.rolle != 'Vorstand'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          to="/verein/abteilungen"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../store'
import cardbutton from '../../../elements/cards/card-button'

export default {
  name: 'Mein-Verein',
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  components: {
    cardbutton,
  },
}
</script>
